<template>
  <div class="characteristics main-block">
    <form @submit.prevent="">
      <!-- <div class="main-block__head">
        <h1 class="main-block__title">Характеристики</h1>
      </div> -->
      <div class="characteristics__block">
        <div class="characteristics__img-block">
          <ImgBlock
            :data-img-list="dataImgList"
            :data-img="dataImg"
            @images="onChangeImage('images', $event)"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  components: {
    ImgBlock: () => ({
      component: import("../../../components/add/addImage"),
    }),
  },
  props: {
    transportData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      size: null,
      sizeLength: null,
      sizeWidth: null,
      sizeHeight: null,
      capacity: null,
      volume: null,
      places: null,
      meters: null,
      weight: null,
      seats: null,
      characteristic: false,
      images: [],
      dataImgList: [],
      dataImg: {
        title: "Перетащите фотографии сюда",
        btn: "Выбрать фотографии",
        type: "image/png, image/gif, image/jpeg",
      },
    };
  },
  methods: {
    async onChange(key, params) {
      this.$emit("onInputHandler", {
        key,
        params,
      });
    },
    async onChangeImage(key, params) {
      this.$emit("onInputHandlerImage", {
        key,
        params,
      });
    },
  },
  computed: {
    ...mapState(["addTransport", "imagesAdd"]),
  },
  mounted() {
    if (this.transportData && this.$route.query.id) {
      this.size = this.transportData?.size;
      this.sizeLength = this.transportData?.sizeLength;
      this.sizeWidth = this.transportData?.sizeWidth;
      this.sizeHeight = this.transportData?.sizeHeight;
      this.capacity = this.transportData?.capacity;
      this.volume = this.transportData?.volume;
      this.places = this.transportData?.places;
      this.meters = this.transportData?.meters;
      this.weight = this.transportData?.weight;
      this.seats = this.transportData?.seats;
      this.dataImgList = this.transportData?.dataImgList;
    }
  },
  created() {
    if (this.transportData && this.$route.query.id) {
      this.dataImgList = this.transportData?.dataImgList;
    }
  },
};
</script>

<style scoped>
.characteristics__block {
  align-items: flex-start;
  /* height: 250px; */
}

.characteristics__img-block {
  width: 100%;
  /* min-height: 250px; */
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* border: 1px dashed #83B1F4; */
  margin: 15px 0 0 0;
}

.characteristics__img-big {
  width: 100%;
  min-height: 175px;
  border-radius: 10px;
}

.characteristics__img-items {
  width: 100%;
  height: 100%;
  margin: 6px;
  overflow-y: auto;
}

.characteristics__img-item {
  margin-right: 5px;
  width: auto;
  height: auto;
}

.characteristics__img-small {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.characteristics__items {
  width: 48%;
  font-size: 12px;
  line-height: 14px;
  color: #707070;
  padding: 5px 0 0 0;
}

.characteristics__item {
  padding-top: 11.5px;
  height: 27.5px;
}

.characteristics__item:not(:last-child) {
  padding-bottom: 11.5px;
  height: 39px;
}

.characteristics__key {
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  text-align: left;
  color: #000000;
}

.characteristics__value {
  margin: 0 3px;
  width: 88px;
  padding-bottom: 3px;
  padding-left: 3px;
  border: none;
  border-radius: 0;
  border-bottom: 1px solid #acacac;
  font-size: 12px;
  line-height: 14px;
  color: #000000;
  text-align: right;
}

.characteristics__value_small {
  width: 40px;
  text-align: center;
}
</style>
